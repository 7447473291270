<template>
  <v-app id="inspire">
    <v-carousel
      cycle
      height="100%"
      hide-delimiter-background
      show-arrows-on-hover
      interval="4000"
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <img class="responsive" :src="slide"/>
          </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-app>
</template>

<script>
  export default {
    name: 'HomeBanner',
    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          "photos/gardens/car1.jpg",
          "photos/gardens/car2.jpg",
          "photos/gardens/car3.jpg",
          "photos/gardens/car4.jpg",
          "photos/gardens/car5.jpg",
          "photos/gardens/car6.jpg",
          "photos/gardens/car7.jpg"
        ],
      }
    },
    methods: {
      onClick2 (e, lan) {
        e.stopPropagation()
        this.$store.dispatch('set_current_lang', lan)
      }
    }
  }
</script>
<style scoped>
.responsive {
  width: 100%;
  height: auto;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
}</style>
